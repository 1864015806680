<template>
	<div v-if="$amp">
		<!-- You might think the above div is unnecessary. YOU WOULD BE WRONG! AMP iframes whose parents are <p>s (which in this markdown world, they all would be) don't show at all. -->
		<amp-iframe
			:src="`https://www.cognitoforms.com/f/${org}/${id}`"
			layout="responsive"
			resizable
			frameborder="0"
			allowtransparency
			scrolling="yes"
			height="422"
			width="800"
			sandbox="allow-scripts allow-popups"
		>
			<div overflow></div>
			<div placeholder></div>
		</amp-iframe>
	</div>
	<div v-else-if="iframe" class="cognito-form">
		<iframe
			ref="embeddedForm"
			:src="`https://www.cognitoforms.com/f/${org}/${id}`"
			style=" width: 100%;
				border: 0;"
			allowtransparency
			height="422"
		>
		</iframe>
	</div>
	<div v-else id="embedded-cognito-form" class="cognito-form" :class="{'cognito-form--on-brand': cognitoStyles}">
		<div ref="embeddedForm"></div>
	</div>
</template>

<script>
	import CognitoFormEmbed from '../../mixins/cognito-form-embed';
	import componentMountedEvent from '../../mixins/content-component-mounted-event';

	const ansel = '#55727b';
	const deep = '#09a098';
	const buttonOrange = '#da5527';
	const matins = '#234652';
	const bgError = '#ffeff0';
	const textError = '#c5151d';
	const gilroy = 'Gilroy, \'Century Gothic\', sans-serif;';
	const strokeInput = '#bdcccc';
	const textPlaceholder = '#92b8b8';
	const systemFont = 'system-ui, -apple-system, blinkmacsystemfont, \'Segoe UI\', roboto, oxygen-sans, ubuntu, cantarell, \'Helvetica Neue\', arial, sans-serif';

	const brandStyles = {
		'font-family': 'inherit',
		'form__background-color': 'transparent',
		'page__background-color': 'white',
		'highlight': deep,
		'highlight-reverse': 'white',
		'background-hsl': '170, 30%, 70%',
		'font-size': '14px',
		'label__color': ansel,
		'header__font-family': 'inherit',
		'heading__font-family': 'inherit',
		'label__font-size': '15.96px',
		'label__font-family': 'inherit',
		'color': ansel,
		'input__color': ansel,
		'negative': textError,
		'negative-reverse': bgError,
		'button-primary__font-family': 'inherit',
		'button-primary__background-color': buttonOrange,
		'button-primary__border-color': buttonOrange,
		'button-primary__font-size': '1.29em',
		'button-primary__font-weight': 'normal',
		'button-secondary__font-family': 'inherit',
		'button-secondary__border-color': buttonOrange,
		'button-secondary__color': buttonOrange,
		'button-secondary__font-size': '1.29em',
		'button-secondary__font-weight': 'normal',
		'a__color': deep,
		'a__font-family': 'inherit',
		'border-radius': 0,
		'form__margins': 0,
		'form__margins--responsive': 0
	};

	export default {
		name: 'CForm',
		mixins: [CognitoFormEmbed, componentMountedEvent],
		props: {
			id: {
				default: null,
				type: String,
				required: true
			},
			org: {
				default: 'VSqLsE7970G2a-Ir3-x6cg',
				type: String
			},
			cognitoStyles: {
				default: true,
				type: Boolean
			},
			styling: {
				default: null,
				type: String
			},
			prefill: {
				default: null,
				type: Object
			},
			optionalDivId: {
				default: null,
				type: String
			}
		},
		head() {
			return this.$amp ? {
				script: [
					{ id: 'amp-iframe', src: 'https://cdn.ampproject.org/v0/amp-iframe-0.1.js', async: true, 'custom-element': 'amp-iframe' }
				]
			} : {};
		},
		computed: {
			getBrandStyles() {
				const styles = [];
				for (const name in brandStyles) {
					if (brandStyles.hasOwnProperty(name))
						styles.push(`--${name}: ${brandStyles[name]};`);
				}

				return styles.join('');
			}
		},
		async mounted() {
			await this.cognitoReady;

			const Cognito = window[this.namespace];
			if (this.iframe) {
				// eslint-disable-next-line no-undef
				Cognito(this.$refs.embeddedForm).on('resize', (e) => {
					this.$refs.embeddedForm.height = e.data.height;
					if (this.$refs.embeddedForm.style.display === 'none')
						this.$refs.embeddedForm.style.display = 'block';
				});
			}

			brandStyles['color'] = matins;
			brandStyles['label__font-size'] = 'inherit';
			brandStyles['label__color'] = 'inherit';
			brandStyles['button-primary__background-color'] = deep;
			brandStyles['button-primary__border-color'] = deep;
			brandStyles['negative'] = textError;
			brandStyles['negative-reverse'] = bgError;
			brandStyles['button-primary__font-size'] = 'inherit';
			brandStyles['button-primary__font-weight'] = '700';
			brandStyles['button-primary__font-family'] = gilroy;
			brandStyles['input__border-color'] = strokeInput;
			brandStyles['input__color'] = matins;
			brandStyles['placeholder__color'] = textPlaceholder;

			if (!this.$amp) {
				if (this.cognitoStyles) {
					if (!this.iframe)
						this.injectStyles(`:root:root:root:root:root .cog-${this.id} { ${this.getBrandStyles} }`);
					else {
						// eslint-disable-next-line no-undef
						Cognito(this.$refs.embeddedForm).setCss(`:root:root:root:root:root .cog-${this.id} { ${this.getBrandStyles} --font-family: ${systemFont}; }`);
					}
				}

				if (this.styling) {
					if (this.iframe)
						// eslint-disable-next-line no-undef
						Cognito(this.$refs.embeddedForm).setCss(this.styling);
					else
						this.injectStyles(this.styling);
				}

				let form;

				if (this.iframe) {
					// eslint-disable-next-line no-undef
					form = Cognito(this.$refs.embeddedForm);
				}
				else {
					// eslint-disable-next-line no-undef
					form = Cognito(this.org).mount(this.id, this.$refs.embeddedForm);
					form.on('ready', () => this.$emit('formReady'));
				}

				if (this.prefill)
					form.prefill(this.prefill);

				form.on('failedValidation', () => this.$emit('failed-validation'));

				form.on('afterSubmit', (e) => this.$emit('after-submit', e));
			}
		},
		beforeDestroy() {
			document.querySelectorAll(`[data-embedded-form-custom-styles="${this.id}"]`).forEach(styleBlock => styleBlock.remove());
		},
		methods: {
			injectStyles(styles) {
				const styleEl = document.createElement('style');
				styleEl.setAttribute('data-embedded-form-custom-styles', this.id);
				// 5 :roots added to exceed specificity of a Cognito Form.
				styleEl.innerHTML = styles;
				document.documentElement.append(styleEl);
			}
		}
	};
</script>

<style lang="scss">
	// It's complicated. But amp iframes need to not be children of <p>s so they're nested in divs.
	// This makes the browser render as <p></p><div><iframe/></div><p></p> and those <p>s look terrible.
	html[⚡] p:empty {
		display: none;
	}

	.cognito-form {
		// To take up some space before loading. Especially helps forms in browser chrome figures.
		min-height: 150px;

		&--on-brand {
			@include cognito-form-styles;
		}
	}
</style>